import React from 'react'
import { Label } from '~/components/ui'

type FormFieldContainerProps = {
  label?: string
  id?: string
  required?: boolean
  className?: string
  children?: React.ReactNode
}

export default function FormFieldContainer(props: FormFieldContainerProps) {
  const { label, id, required = false, className, children } = props

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={id} className="mb-3 block">
          {label}
          {required && <span className="text-red-600">*</span>}
        </Label>
      )}
      {children}
    </div>
  )
}
